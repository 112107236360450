<template>
  <v-container id="regular-tables-view" fluid tag="section">
    <v-alert
      v-if="alert.visable"
      color="primary"
      dark
      icon="fa-regular fa-hexagon-exclamation"
      border="left"
      prominent
    >
      {{ alert.description }}
    </v-alert>


    <view-intro class="text-start" heading="Alle groepen"/>

    <div class="d-flex justify-content-center" style="margin-top: 30vh;" v-if="loading">
      <v-progress-circular
        indeterminate
        color="primary"
      ></v-progress-circular>
    </div>
    <div v-else style="gap: 20px; flex-wrap: wrap;" class="d-md justify-content-sm-start justify-content-center d-flex">
      <div @click="goToGroup(group)" class="groupBlock shadow p-3 mb-5 bg-body rounded-4"
           v-for="(group, index) in productGroups" :key="index"
      >
        <p class="h5 mb-0">{{ group.group }}</p>
        <p class="mb-0 ml-3">{{ group.count }}</p>
      </div>
<!--      <div @click="dialog = true;" class="groupBlock shadow p-3 mb-5 bg-body rounded-4">-->
<!--        <i class="fa-2x fa-regular fa-plus"></i>-->
<!--      </div>-->
    </div>
    <v-dialog
      v-model="dialog"
      persistent
      max-width="600px"
    >
      <v-card>
        <v-card-title>
          <span class="text-h3">Nieuwe groep aanmaken</span>
        </v-card-title>
        <vue-form-generator ref="form" class="px-5 mt-3" :schema="schema" :model="model" :options="formOptions"
        ></vue-form-generator>

        <v-card-text class="text-danger fw-bold">
          {{ dialogAlert }}
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="blue darken-1"
            text
            @click="dialog = false; dialogAlert = ''; model.groupName =''"
          >Annuleren
          </v-btn>
          <v-btn
            color="blue darken-1"
            text
            @click="createNewGroup"
          >Opslaan
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
  import VueFormGenerator from 'vue-form-generator'

  export default {
    name: 'ProductsDocument',
    data () {
      this.$auth.getProducts().then(collectionsWithData => {
        this.productGroups = this.$filter.filterCollection(collectionsWithData)
      }).catch(err => {
        this.alert.visable = true
        this.alert.description = `Er is iets mis gegaan error: ${err} Neem graag contact met beheerder`
      }).finally(() => {
        this.loading = false
      })

      return {
        dialogAlert: '',
        model: {
          groupName: '',
        },
        schema: {
          fields: [
            {
              type: 'input',
              inputType: 'text',
              label: 'Groep naam',
              model: 'groupName',
              max: 15,
              required: true,
              hint: 'Maximaal 15 karakters',
              validator: VueFormGenerator.validators.string,
            },
          ],
        },
        formOptions: {
          validateAfterLoad: false,
          validateAfterChanged: true,
          validateAsync: true,
        },
        dialog: false,
        loading: true,
        alert: {
          visible: false,
          description: '',
        },
        productGroups: [],
      }
    },
    methods: {
      async createNewGroup () {
        const validationErrors = await this.$refs.form.validate()
        if (validationErrors.length !== 0) return

        const { groupName } = this.model
        const element = { data: [] }
        this.$auth.createNewGroupInProduct(groupName, element).then(res => {
          if (res === 'Document already exist') {
            this.dialogAlert = 'Deze groep bestaat al'
            return
          }
          this.dialog = false
          this.loading = true
          this.$auth.getProducts().then(collectionsWithData => {
            this.productGroups = this.$filter.filterCollection(collectionsWithData)
          }).catch(err => {
            this.alert.visable = true
            this.alert.description = `Er is iets mis gegaan error: ${err} Neem graag contact met beheerder`
          }).finally(() => {
            this.loading = false
          })
        }).catch(err => {
          this.alert.visable = true
          this.alert.description = `Er is iets mis gegaan error: ${err} Neem graag contact met beheerder`
        })
      },
      goToGroup (group) {
        const document = group.group
        this.$router.push(`/products/${document}`)
      },
    },
  }
</script>

<style scoped>

.groupBlock {
  min-width: 150px;
  height: 150px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center
}
</style>
